import React from 'react';
import {Link} from 'gatsby'
import VectorShape11 from '../WeServe/vector-shape11.png'

const WeServe = () => {
    return (
        <section className="industries-serve-area bg-200e36 pt-70">
            <div className="container">
                <div className="section-title">
                    <h4>Start, build and maintain your business with stress-free compliance</h4>

                </div>

                <div className="row">

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-factory"></i>
                            </div>
                            Company Formation
                            <Link to="https://smb.comply.me/company-registration" className="link-btn"></Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-hospital"></i>
                            </div>
                            Business Taxes
                            <Link to="https://smb.comply.me/compliance/taxes" className="link-btn"></Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-tracking"></i>
                            </div>
                            Business Compliance
                            <Link to="https://smb.comply.me/compliance" className="link-btn"></Link>
                        </div>
                    </div>

                    {/* <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-industries-serve-box">
                            <div className="icon">
                                <i className="flaticon-hospital"></i>
                            </div>
                             Tools
                            <Link to="/funding" className="link-btn"></Link>
                        </div>
                    </div> */}


                    {/* <div className="col-lg-3 col-sm-6 col-md-6">
                        <Link to="/registracia/" className="default-btn">
                                    <i className="flaticon-right"></i> 
                                    Get Expert Advice<span></span>
                        </Link>
                    </div> */}
                </div>
            </div>

            <div className="section-title">
            <br/>
                    <Link to="/registracia" className="default-btn">
                                    <i className="flaticon-right"></i> 
                                    Get Expert Advice<span></span>
                    </Link>
            </div>
            <div className="vector-shape11">
                <img src={VectorShape11} alt="Vector Shape" />
            </div>
        </section>
    )
}

export default WeServe;